@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500|Ubuntu:300,300i,400&subset=cyrillic");
.PaletteColor_0 {
  background: red;
  stroke: red;
  fill: red; }

.PaletteColor_1 {
  background: #0050ff;
  stroke: #0050ff;
  fill: #0050ff; }

.PaletteColor_2 {
  background: #a1ff00;
  stroke: #a1ff00;
  fill: #a1ff00; }

.PaletteColor_3 {
  background: #ff00f1;
  stroke: #ff00f1;
  fill: #ff00f1; }

.PaletteColor_4 {
  background: #00ffbd;
  stroke: #00ffbd;
  fill: #00ffbd; }

.PaletteColor_5 {
  background: #ff6c00;
  stroke: #ff6c00;
  fill: #ff6c00; }

.PaletteColor_6 {
  background: #1c00ff;
  stroke: #1c00ff;
  fill: #1c00ff; }

.PaletteColor_7 {
  background: #34ff00;
  stroke: #34ff00;
  fill: #34ff00; }

.PaletteColor_8 {
  background: #ff0085;
  stroke: #ff0085;
  fill: #ff0085; }

.PaletteColor_9 {
  background: #00d5ff;
  stroke: #00d5ff;
  fill: #00d5ff; }

.PaletteColor_10 {
  background: #ffd900;
  stroke: #ffd900;
  fill: #ffd900; }

.PaletteColor_11 {
  background: #8800ff;
  stroke: #8800ff;
  fill: #8800ff; }

.PaletteColor_12 {
  background: #00ff38;
  stroke: #00ff38;
  fill: #00ff38; }

.PaletteColor_13 {
  background: #ff0019;
  stroke: #ff0019;
  fill: #ff0019; }

.PaletteColor_14 {
  background: #0069ff;
  stroke: #0069ff;
  fill: #0069ff; }

.PaletteColor_15 {
  background: #b9ff00;
  stroke: #b9ff00;
  fill: #b9ff00; }

.PaletteColor_16 {
  background: #f400ff;
  stroke: #f400ff;
  fill: #f400ff; }

.PaletteColor_17 {
  background: #00ffa4;
  stroke: #00ffa4;
  fill: #00ffa4; }

.PaletteColor_18 {
  background: #ff5400;
  stroke: #ff5400;
  fill: #ff5400; }

.PaletteColor_19 {
  background: #0300ff;
  stroke: #0300ff;
  fill: #0300ff; }

.PaletteColor_20 {
  background: #4dff00;
  stroke: #4dff00;
  fill: #4dff00; }

.PaletteColor_21 {
  background: #ff009d;
  stroke: #ff009d;
  fill: #ff009d; }

.PaletteColor_22 {
  background: #00eeff;
  stroke: #00eeff;
  fill: #00eeff; }

.PaletteColor_23 {
  background: #ffc000;
  stroke: #ffc000;
  fill: #ffc000; }

.PaletteColor_24 {
  background: #7000ff;
  stroke: #7000ff;
  fill: #7000ff; }

.PaletteColor_25 {
  background: #00ff1f;
  stroke: #00ff1f;
  fill: #00ff1f; }

.PaletteColor_26 {
  background: #ff0031;
  stroke: #ff0031;
  fill: #ff0031; }

.PaletteColor_27 {
  background: #0081ff;
  stroke: #0081ff;
  fill: #0081ff; }

.PaletteColor_28 {
  background: #d2ff00;
  stroke: #d2ff00;
  fill: #d2ff00; }

.PaletteColor_29 {
  background: #dc00ff;
  stroke: #dc00ff;
  fill: #dc00ff; }

.PaletteColor_30 {
  background: #00ff8c;
  stroke: #00ff8c;
  fill: #00ff8c; }

.PaletteColor_31 {
  background: #ff3b00;
  stroke: #ff3b00;
  fill: #ff3b00; }

.PaletteColor_32 {
  background: #0015ff;
  stroke: #0015ff;
  fill: #0015ff; }

.PaletteColor_33 {
  background: #65ff00;
  stroke: #65ff00;
  fill: #65ff00; }

.PaletteColor_34 {
  background: #ff00b6;
  stroke: #ff00b6;
  fill: #ff00b6; }

.PaletteColor_35 {
  background: #00fff8;
  stroke: #00fff8;
  fill: #00fff8; }

.PaletteColor_36 {
  background: #ffa800;
  stroke: #ffa800;
  fill: #ffa800; }

.PaletteColor_37 {
  background: #5700ff;
  stroke: #5700ff;
  fill: #5700ff; }

.PaletteColor_38 {
  background: #00ff07;
  stroke: #00ff07;
  fill: #00ff07; }

.PaletteColor_39 {
  background: #ff004a;
  stroke: #ff004a;
  fill: #ff004a; }

.PaletteColor_40 {
  background: #009aff;
  stroke: #009aff;
  fill: #009aff; }

.PaletteColor_41 {
  background: #eaff00;
  stroke: #eaff00;
  fill: #eaff00; }

.PaletteColor_42 {
  background: #c300ff;
  stroke: #c300ff;
  fill: #c300ff; }

.PaletteColor_43 {
  background: #00ff73;
  stroke: #00ff73;
  fill: #00ff73; }

.PaletteColor_44 {
  background: #ff2300;
  stroke: #ff2300;
  fill: #ff2300; }

.PaletteColor_45 {
  background: #002eff;
  stroke: #002eff;
  fill: #002eff; }

.PaletteColor_46 {
  background: #7eff00;
  stroke: #7eff00;
  fill: #7eff00; }

.PaletteColor_47 {
  background: #ff00ce;
  stroke: #ff00ce;
  fill: #ff00ce; }

.PaletteColor_48 {
  background: #00ffdf;
  stroke: #00ffdf;
  fill: #00ffdf; }

.PaletteColor_49 {
  background: #ff8f00;
  stroke: #ff8f00;
  fill: #ff8f00; }

.PaletteColor_50 {
  background: #3f00ff;
  stroke: #3f00ff;
  fill: #3f00ff; }

.PaletteColor_51 {
  background: #12ff00;
  stroke: #12ff00;
  fill: #12ff00; }

.PaletteColor_52 {
  background: #ff0062;
  stroke: #ff0062;
  fill: #ff0062; }

.PaletteColor_53 {
  background: #00b2ff;
  stroke: #00b2ff;
  fill: #00b2ff; }

.PaletteColor_54 {
  background: #fffb00;
  stroke: #fffb00;
  fill: #fffb00; }

.PaletteColor_55 {
  background: #ab00ff;
  stroke: #ab00ff;
  fill: #ab00ff; }

.PaletteColor_56 {
  background: #00ff5b;
  stroke: #00ff5b;
  fill: #00ff5b; }

.PaletteColor_57 {
  background: #ff0a00;
  stroke: #ff0a00;
  fill: #ff0a00; }

.PaletteColor_58 {
  background: #0046ff;
  stroke: #0046ff;
  fill: #0046ff; }

.PaletteColor_59 {
  background: #96ff00;
  stroke: #96ff00;
  fill: #96ff00; }

.PaletteColor_60 {
  background: #ff00e7;
  stroke: #ff00e7;
  fill: #ff00e7; }

.PaletteColor_61 {
  background: #00ffc7;
  stroke: #00ffc7;
  fill: #00ffc7; }

.PaletteColor_62 {
  background: #ff7700;
  stroke: #ff7700;
  fill: #ff7700; }

.PaletteColor_63 {
  background: #2600ff;
  stroke: #2600ff;
  fill: #2600ff; }

.PaletteColor_64 {
  background: #2aff00;
  stroke: #2aff00;
  fill: #2aff00; }

.PaletteColor_65 {
  background: #ff007b;
  stroke: #ff007b;
  fill: #ff007b; }

.PaletteColor_66 {
  background: #00cbff;
  stroke: #00cbff;
  fill: #00cbff; }

.PaletteColor_67 {
  background: #ffe300;
  stroke: #ffe300;
  fill: #ffe300; }

.PaletteColor_68 {
  background: #9200ff;
  stroke: #9200ff;
  fill: #9200ff; }

.PaletteColor_69 {
  background: #00ff42;
  stroke: #00ff42;
  fill: #00ff42; }

.PaletteColor_70 {
  background: #ff000e;
  stroke: #ff000e;
  fill: #ff000e; }

.PaletteColor_71 {
  background: #005fff;
  stroke: #005fff;
  fill: #005fff; }

.PaletteColor_72 {
  background: #afff00;
  stroke: #afff00;
  fill: #afff00; }

.PaletteColor_73 {
  background: magenta;
  stroke: magenta;
  fill: magenta; }

.PaletteColor_74 {
  background: #00ffae;
  stroke: #00ffae;
  fill: #00ffae; }

.PaletteColor_75 {
  background: #ff5e00;
  stroke: #ff5e00;
  fill: #ff5e00; }

.PaletteColor_76 {
  background: #0e00ff;
  stroke: #0e00ff;
  fill: #0e00ff; }

.PaletteColor_77 {
  background: #43ff00;
  stroke: #43ff00;
  fill: #43ff00; }

.PaletteColor_78 {
  background: #ff0093;
  stroke: #ff0093;
  fill: #ff0093; }

.PaletteColor_79 {
  background: #00e3ff;
  stroke: #00e3ff;
  fill: #00e3ff; }

.PaletteColor_80 {
  background: #ffca00;
  stroke: #ffca00;
  fill: #ffca00; }

.PaletteColor_81 {
  background: #7a00ff;
  stroke: #7a00ff;
  fill: #7a00ff; }

.PaletteColor_82 {
  background: #00ff2a;
  stroke: #00ff2a;
  fill: #00ff2a; }

.PaletteColor_83 {
  background: #ff0027;
  stroke: #ff0027;
  fill: #ff0027; }

.PaletteColor_84 {
  background: #0077ff;
  stroke: #0077ff;
  fill: #0077ff; }

.PaletteColor_85 {
  background: #c7ff00;
  stroke: #c7ff00;
  fill: #c7ff00; }

.PaletteColor_86 {
  background: #e600ff;
  stroke: #e600ff;
  fill: #e600ff; }

.PaletteColor_87 {
  background: #00ff96;
  stroke: #00ff96;
  fill: #00ff96; }

.PaletteColor_88 {
  background: #ff4600;
  stroke: #ff4600;
  fill: #ff4600; }

.PaletteColor_89 {
  background: #000bff;
  stroke: #000bff;
  fill: #000bff; }

.PaletteColor_90 {
  background: #5bff00;
  stroke: #5bff00;
  fill: #5bff00; }

.PaletteColor_91 {
  background: #ff00ac;
  stroke: #ff00ac;
  fill: #ff00ac; }

.PaletteColor_92 {
  background: #00fcff;
  stroke: #00fcff;
  fill: #00fcff; }

.PaletteColor_93 {
  background: #ffb200;
  stroke: #ffb200;
  fill: #ffb200; }

.PaletteColor_94 {
  background: #6100ff;
  stroke: #6100ff;
  fill: #6100ff; }

.PaletteColor_95 {
  background: #00ff11;
  stroke: #00ff11;
  fill: #00ff11; }

.PaletteColor_96 {
  background: #ff003f;
  stroke: #ff003f;
  fill: #ff003f; }

.PaletteColor_97 {
  background: #0090ff;
  stroke: #0090ff;
  fill: #0090ff; }

.PaletteColor_98 {
  background: #e0ff00;
  stroke: #e0ff00;
  fill: #e0ff00; }

.PaletteColor_99 {
  background: #ce00ff;
  stroke: #ce00ff;
  fill: #ce00ff; }

.PaletteColor_Default {
  background: #BBB;
  stroke: #BBB;
  fill: #BBB; }

.PaletteColor_Red {
  background: #df2100;
  stroke: #df2100;
  fill: #df2100; }

.PaletteColor_Dark {
  background: #0b171c;
  stroke: #0b171c;
  fill: #0b171c; }

.PaletteColor_0 {
  background: red;
  stroke: red;
  fill: red; }

.PaletteColor_1 {
  background: #0050ff;
  stroke: #0050ff;
  fill: #0050ff; }

.PaletteColor_2 {
  background: #a1ff00;
  stroke: #a1ff00;
  fill: #a1ff00; }

.PaletteColor_3 {
  background: #ff00f1;
  stroke: #ff00f1;
  fill: #ff00f1; }

.PaletteColor_4 {
  background: #00ffbd;
  stroke: #00ffbd;
  fill: #00ffbd; }

.PaletteColor_5 {
  background: #ff6c00;
  stroke: #ff6c00;
  fill: #ff6c00; }

.PaletteColor_6 {
  background: #1c00ff;
  stroke: #1c00ff;
  fill: #1c00ff; }

.PaletteColor_7 {
  background: #34ff00;
  stroke: #34ff00;
  fill: #34ff00; }

.PaletteColor_8 {
  background: #ff0085;
  stroke: #ff0085;
  fill: #ff0085; }

.PaletteColor_9 {
  background: #00d5ff;
  stroke: #00d5ff;
  fill: #00d5ff; }

.PaletteColor_10 {
  background: #ffd900;
  stroke: #ffd900;
  fill: #ffd900; }

.PaletteColor_11 {
  background: #8800ff;
  stroke: #8800ff;
  fill: #8800ff; }

.PaletteColor_12 {
  background: #00ff38;
  stroke: #00ff38;
  fill: #00ff38; }

.PaletteColor_13 {
  background: #ff0019;
  stroke: #ff0019;
  fill: #ff0019; }

.PaletteColor_14 {
  background: #0069ff;
  stroke: #0069ff;
  fill: #0069ff; }

.PaletteColor_15 {
  background: #b9ff00;
  stroke: #b9ff00;
  fill: #b9ff00; }

.PaletteColor_16 {
  background: #f400ff;
  stroke: #f400ff;
  fill: #f400ff; }

.PaletteColor_17 {
  background: #00ffa4;
  stroke: #00ffa4;
  fill: #00ffa4; }

.PaletteColor_18 {
  background: #ff5400;
  stroke: #ff5400;
  fill: #ff5400; }

.PaletteColor_19 {
  background: #0300ff;
  stroke: #0300ff;
  fill: #0300ff; }

.PaletteColor_20 {
  background: #4dff00;
  stroke: #4dff00;
  fill: #4dff00; }

.PaletteColor_21 {
  background: #ff009d;
  stroke: #ff009d;
  fill: #ff009d; }

.PaletteColor_22 {
  background: #00eeff;
  stroke: #00eeff;
  fill: #00eeff; }

.PaletteColor_23 {
  background: #ffc000;
  stroke: #ffc000;
  fill: #ffc000; }

.PaletteColor_24 {
  background: #7000ff;
  stroke: #7000ff;
  fill: #7000ff; }

.PaletteColor_25 {
  background: #00ff1f;
  stroke: #00ff1f;
  fill: #00ff1f; }

.PaletteColor_26 {
  background: #ff0031;
  stroke: #ff0031;
  fill: #ff0031; }

.PaletteColor_27 {
  background: #0081ff;
  stroke: #0081ff;
  fill: #0081ff; }

.PaletteColor_28 {
  background: #d2ff00;
  stroke: #d2ff00;
  fill: #d2ff00; }

.PaletteColor_29 {
  background: #dc00ff;
  stroke: #dc00ff;
  fill: #dc00ff; }

.PaletteColor_30 {
  background: #00ff8c;
  stroke: #00ff8c;
  fill: #00ff8c; }

.PaletteColor_31 {
  background: #ff3b00;
  stroke: #ff3b00;
  fill: #ff3b00; }

.PaletteColor_32 {
  background: #0015ff;
  stroke: #0015ff;
  fill: #0015ff; }

.PaletteColor_33 {
  background: #65ff00;
  stroke: #65ff00;
  fill: #65ff00; }

.PaletteColor_34 {
  background: #ff00b6;
  stroke: #ff00b6;
  fill: #ff00b6; }

.PaletteColor_35 {
  background: #00fff8;
  stroke: #00fff8;
  fill: #00fff8; }

.PaletteColor_36 {
  background: #ffa800;
  stroke: #ffa800;
  fill: #ffa800; }

.PaletteColor_37 {
  background: #5700ff;
  stroke: #5700ff;
  fill: #5700ff; }

.PaletteColor_38 {
  background: #00ff07;
  stroke: #00ff07;
  fill: #00ff07; }

.PaletteColor_39 {
  background: #ff004a;
  stroke: #ff004a;
  fill: #ff004a; }

.PaletteColor_40 {
  background: #009aff;
  stroke: #009aff;
  fill: #009aff; }

.PaletteColor_41 {
  background: #eaff00;
  stroke: #eaff00;
  fill: #eaff00; }

.PaletteColor_42 {
  background: #c300ff;
  stroke: #c300ff;
  fill: #c300ff; }

.PaletteColor_43 {
  background: #00ff73;
  stroke: #00ff73;
  fill: #00ff73; }

.PaletteColor_44 {
  background: #ff2300;
  stroke: #ff2300;
  fill: #ff2300; }

.PaletteColor_45 {
  background: #002eff;
  stroke: #002eff;
  fill: #002eff; }

.PaletteColor_46 {
  background: #7eff00;
  stroke: #7eff00;
  fill: #7eff00; }

.PaletteColor_47 {
  background: #ff00ce;
  stroke: #ff00ce;
  fill: #ff00ce; }

.PaletteColor_48 {
  background: #00ffdf;
  stroke: #00ffdf;
  fill: #00ffdf; }

.PaletteColor_49 {
  background: #ff8f00;
  stroke: #ff8f00;
  fill: #ff8f00; }

.PaletteColor_50 {
  background: #3f00ff;
  stroke: #3f00ff;
  fill: #3f00ff; }

.PaletteColor_51 {
  background: #12ff00;
  stroke: #12ff00;
  fill: #12ff00; }

.PaletteColor_52 {
  background: #ff0062;
  stroke: #ff0062;
  fill: #ff0062; }

.PaletteColor_53 {
  background: #00b2ff;
  stroke: #00b2ff;
  fill: #00b2ff; }

.PaletteColor_54 {
  background: #fffb00;
  stroke: #fffb00;
  fill: #fffb00; }

.PaletteColor_55 {
  background: #ab00ff;
  stroke: #ab00ff;
  fill: #ab00ff; }

.PaletteColor_56 {
  background: #00ff5b;
  stroke: #00ff5b;
  fill: #00ff5b; }

.PaletteColor_57 {
  background: #ff0a00;
  stroke: #ff0a00;
  fill: #ff0a00; }

.PaletteColor_58 {
  background: #0046ff;
  stroke: #0046ff;
  fill: #0046ff; }

.PaletteColor_59 {
  background: #96ff00;
  stroke: #96ff00;
  fill: #96ff00; }

.PaletteColor_60 {
  background: #ff00e7;
  stroke: #ff00e7;
  fill: #ff00e7; }

.PaletteColor_61 {
  background: #00ffc7;
  stroke: #00ffc7;
  fill: #00ffc7; }

.PaletteColor_62 {
  background: #ff7700;
  stroke: #ff7700;
  fill: #ff7700; }

.PaletteColor_63 {
  background: #2600ff;
  stroke: #2600ff;
  fill: #2600ff; }

.PaletteColor_64 {
  background: #2aff00;
  stroke: #2aff00;
  fill: #2aff00; }

.PaletteColor_65 {
  background: #ff007b;
  stroke: #ff007b;
  fill: #ff007b; }

.PaletteColor_66 {
  background: #00cbff;
  stroke: #00cbff;
  fill: #00cbff; }

.PaletteColor_67 {
  background: #ffe300;
  stroke: #ffe300;
  fill: #ffe300; }

.PaletteColor_68 {
  background: #9200ff;
  stroke: #9200ff;
  fill: #9200ff; }

.PaletteColor_69 {
  background: #00ff42;
  stroke: #00ff42;
  fill: #00ff42; }

.PaletteColor_70 {
  background: #ff000e;
  stroke: #ff000e;
  fill: #ff000e; }

.PaletteColor_71 {
  background: #005fff;
  stroke: #005fff;
  fill: #005fff; }

.PaletteColor_72 {
  background: #afff00;
  stroke: #afff00;
  fill: #afff00; }

.PaletteColor_73 {
  background: magenta;
  stroke: magenta;
  fill: magenta; }

.PaletteColor_74 {
  background: #00ffae;
  stroke: #00ffae;
  fill: #00ffae; }

.PaletteColor_75 {
  background: #ff5e00;
  stroke: #ff5e00;
  fill: #ff5e00; }

.PaletteColor_76 {
  background: #0e00ff;
  stroke: #0e00ff;
  fill: #0e00ff; }

.PaletteColor_77 {
  background: #43ff00;
  stroke: #43ff00;
  fill: #43ff00; }

.PaletteColor_78 {
  background: #ff0093;
  stroke: #ff0093;
  fill: #ff0093; }

.PaletteColor_79 {
  background: #00e3ff;
  stroke: #00e3ff;
  fill: #00e3ff; }

.PaletteColor_80 {
  background: #ffca00;
  stroke: #ffca00;
  fill: #ffca00; }

.PaletteColor_81 {
  background: #7a00ff;
  stroke: #7a00ff;
  fill: #7a00ff; }

.PaletteColor_82 {
  background: #00ff2a;
  stroke: #00ff2a;
  fill: #00ff2a; }

.PaletteColor_83 {
  background: #ff0027;
  stroke: #ff0027;
  fill: #ff0027; }

.PaletteColor_84 {
  background: #0077ff;
  stroke: #0077ff;
  fill: #0077ff; }

.PaletteColor_85 {
  background: #c7ff00;
  stroke: #c7ff00;
  fill: #c7ff00; }

.PaletteColor_86 {
  background: #e600ff;
  stroke: #e600ff;
  fill: #e600ff; }

.PaletteColor_87 {
  background: #00ff96;
  stroke: #00ff96;
  fill: #00ff96; }

.PaletteColor_88 {
  background: #ff4600;
  stroke: #ff4600;
  fill: #ff4600; }

.PaletteColor_89 {
  background: #000bff;
  stroke: #000bff;
  fill: #000bff; }

.PaletteColor_90 {
  background: #5bff00;
  stroke: #5bff00;
  fill: #5bff00; }

.PaletteColor_91 {
  background: #ff00ac;
  stroke: #ff00ac;
  fill: #ff00ac; }

.PaletteColor_92 {
  background: #00fcff;
  stroke: #00fcff;
  fill: #00fcff; }

.PaletteColor_93 {
  background: #ffb200;
  stroke: #ffb200;
  fill: #ffb200; }

.PaletteColor_94 {
  background: #6100ff;
  stroke: #6100ff;
  fill: #6100ff; }

.PaletteColor_95 {
  background: #00ff11;
  stroke: #00ff11;
  fill: #00ff11; }

.PaletteColor_96 {
  background: #ff003f;
  stroke: #ff003f;
  fill: #ff003f; }

.PaletteColor_97 {
  background: #0090ff;
  stroke: #0090ff;
  fill: #0090ff; }

.PaletteColor_98 {
  background: #e0ff00;
  stroke: #e0ff00;
  fill: #e0ff00; }

.PaletteColor_99 {
  background: #ce00ff;
  stroke: #ce00ff;
  fill: #ce00ff; }

.PaletteColor_Default {
  background: #BBB;
  stroke: #BBB;
  fill: #BBB; }

.PaletteColor_Red {
  background: #df2100;
  stroke: #df2100;
  fill: #df2100; }

.PaletteColor_Dark {
  background: #0b171c;
  stroke: #0b171c;
  fill: #0b171c; }

@keyframes hintFadeIn {
  0% {
    opacity: 0; }
  80% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/*
	HTML5 Reset :: style.css
	----------------------------------------------------------
	We have learned much from/been inspired by/taken code where offered from:

	Eric Meyer					:: https://meyerweb.com
	HTML5 Doctor				:: https://html5doctor.com
	and the HTML5 Boilerplate	:: https://html5boilerplate.com

-------------------------------------------------------------------------------*/
/* Let's default this puppy out
-------------------------------------------------------------------------------*/
html, body, body div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, menu, nav, section, time, mark, audio, video, details, summary {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: normal;
  vertical-align: baseline;
  background: transparent; }

article, aside, figure, footer, header, nav, section, details, summary {
  display: block; }

/* Handle box-sizing while better addressing child elements:
   https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
html {
  box-sizing: border-box; }

*,
*:before,
*:after {
  box-sizing: inherit; }

/* consider resetting the default cursor: https://gist.github.com/murtaugh/5247154 */
/* Responsive images and other embedded objects
*/
img,
object,
embed {
  max-width: 100%; }

/*
   Note: keeping IMG here will cause problems if you're using foreground images as sprites.
	 In fact, it *will* cause problems with Google Maps' controls at small size.
	If this is the case for you, try uncommenting the following:

#map img {
		max-width: none;
}
*/
/* force a vertical scrollbar to prevent a jumpy page */
html {
  overflow-y: scroll; }

/* we use a lot of ULs that aren't bulleted.
	don't forget to restore the bullets within content. */
ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none; }

a:link {
  color: inherit; }

a:visited {
  color: inherit; }

a:hover {
  color: inherit; }

a:active {
  color: inherit; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help; }

/* tables still need cellspacing="0" in the markup */
table {
  border-collapse: collapse;
  border-spacing: 0; }

th {
  font-weight: bold;
  vertical-align: bottom; }

td {
  font-weight: normal;
  vertical-align: top; }

hr {
  display: block;
  width: 100%;
  height: 1px;
  border: 0;
  border-top: 1px solid #EEE;
  margin: 1em 0;
  padding: 0;
  flex: 0 0 auto; }

input, select {
  vertical-align: middle; }

pre {
  white-space: pre;
  /* CSS2 */
  white-space: pre-wrap;
  /* CSS 2.1 */
  white-space: pre-line;
  /* CSS 3 (and 2.1 as well, actually) */
  word-wrap: break-word;
  /* IE */ }

input[type="radio"] {
  vertical-align: text-bottom; }

input[type="checkbox"] {
  vertical-align: bottom; }

.ie7 input[type="checkbox"] {
  vertical-align: baseline; }

.ie6 input {
  vertical-align: text-bottom; }

select, input, textarea {
  font: 99% sans-serif; }

table {
  font-size: inherit;
  font: 100%; }

small {
  font-size: 85%; }

strong {
  font-weight: bold; }

td, td img {
  vertical-align: top; }

/* Make sure sup and sub don't mess with your line-heights https://gist.github.com/413930 */
sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* standardize any monospaced elements */
pre, code, kbd, samp {
  font-family: monospace, sans-serif; }

/* hand cursor on clickable elements */
.clickable,
label,
input[type=button],
input[type=submit],
input[type=file],
button {
  cursor: pointer; }

/* Webkit browsers add a 2px margin outside the chrome of form elements */
button, input, select, textarea {
  margin: 0; }

/* make buttons play nice in IE */
button,
input[type=button] {
  width: auto;
  overflow: visible; }

/* scale images in IE7 more attractively */
.ie7 img {
  -ms-interpolation-mode: bicubic; }

/* prevent BG image flicker upon hover
   (commented out as usage is rare, and the filter syntax messes with some pre-processors)
.ie6 html {filter: expression(document.execCommand("BackgroundImageCache", false, true));}
*/
/* let's clear some floats */
.clearfix:before, .clearfix:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden; }

.clearfix:after {
  clear: both; }

.clearfix {
  zoom: 1; }

b, strong {
  font-weight: bold; }

.Toastify__toast-container {
  pointer-events: none; }

.Toastify__toast {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  min-height: 64px;
  margin-bottom: 8px;
  padding: 8px;
  border: 1px solid #0b597a;
  border-radius: 4px;
  font-family: "Ubuntu", sans-serif !important;
  font-size: 14px;
  color: #333;
  background: #FFF !important;
  align-items: center;
  pointer-events: initial; }
  .Toastify__toast::before {
    content: '';
    height: 16px;
    width: 16px;
    border-radius: 16px;
    margin-right: 16px;
    margin-left: 8px; }
  .Toastify__toast--success {
    border: 2px solid #00bb00; }
    .Toastify__toast--success::before {
      background-color: #00bb00; }
  .Toastify__toast--warning {
    border: 2px solid #f38400; }
    .Toastify__toast--warning::before {
      background-color: #f38400; }
  .Toastify__toast--error {
    border: 2px solid #df2100; }
    .Toastify__toast--error::before {
      background-color: #df2100; }

.SVG {
  display: inline-block;
  height: 100%;
  width: 100%; }

.LoginForm {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url("../img/login_bg.jpg");
  background-size: cover;
  background-position: center; }
  .LoginForm__Window {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    width: 280px;
    background-color: #FFF;
    padding: 24px; }
  .LoginForm__Validation {
    margin-bottom: 8px;
    color: #df2100;
    text-align: center; }
  .LoginForm__Logo {
    height: 48px;
    margin-top: -8px; }
  .LoginForm__Title {
    text-align: center;
    margin-bottom: 16px;
    margin-top: 8px;
    color: #888;
    text-transform: uppercase; }
  .LoginForm__FieldsBox {
    animation-name: fadeIn;
    animation-fill-mode: both;
    animation-duration: .5s; }
  .LoginForm__Input {
    width: 100%;
    margin-bottom: 16px; }
  .LoginForm__Submit {
    text-align: center; }
    .LoginForm__Submit > input {
      width: 120px; }
  .LoginForm__Extra {
    display: flex;
    justify-content: space-between; }
  .LoginForm__Splitter {
    margin: 16px 0px;
    border-bottom: 1px solid #EEE; }

.UserBadge {
  display: flex;
  right: 16px;
  flex-direction: row-reverse;
  padding-left: 8px;
  pointer-events: auto; }
  .UserBadge__Avatar {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    position: relative;
    height: 48px;
    width: 48px;
    border-radius: 48px;
    box-sizing: content-box;
    border: 3px solid #FFF;
    background-repeat: round;
    background-image: url("../img/account_dummy.png");
    z-index: 1; }
  .UserBadge__Title {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    position: relative;
    height: 32px;
    top: 12px;
    right: -8px;
    padding: 0px 16px;
    line-height: 32px;
    border-radius: 16px 0 0 16px;
    background-color: #FFF; }
  .UserBadge__Name {
    white-space: nowrap;
    margin-right: 16px;
    font-weight: bold; }

.Catalog {
  height: 100%;
  position: relative; }
  .Catalog__Map {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 1;
    background-image: url("../img/map_grid.gif"); }
  .Catalog__Logo {
    position: absolute;
    opacity: 0.55;
    height: 48px;
    width: 96px;
    bottom: 8px;
    margin-left: 8px;
    z-index: 2; }
  .Catalog__Header {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 16px;
    left: 16px;
    right: 16px;
    z-index: 3;
    pointer-events: none; }
  .Catalog__HeaderLeft {
    display: flex;
    flex: 0 0 auto; }
  .Catalog__HeaderRight {
    display: flex;
    align-items: center;
    flex-wrap: wrap-reverse;
    padding-left: 16px;
    flex: 1 1 auto;
    justify-content: flex-end; }
  .Catalog__Body {
    position: absolute;
    top: 96px;
    left: 16px;
    right: 16px;
    bottom: 16px;
    z-index: 2;
    pointer-events: none;
    overflow: hidden; }
  .Catalog__Tabs {
    display: flex;
    height: 100%;
    flex-direction: row; }
  .Catalog__LeftTabDynamicIndent {
    flex: 0 1 48px; }
  .Catalog__RightTabDynamicIndent {
    flex: 0 1 48px; }
  .Catalog__RightTabTopIndent {
    flex: 0 0 auto;
    height: calc(200px - 12vw);
    max-height: 48px; }
  .Catalog__LeftTab {
    flex: 0 0 480px;
    display: flex;
    flex-direction: column; }
  .Catalog__RightTab {
    flex: 1 0 480px;
    align-items: flex-start;
    padding-left: 16px;
    display: flex;
    flex-direction: column; }

.CatalogMenu {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  display: flex;
  height: 64px;
  flex: 0 0 auto;
  pointer-events: auto;
  background-color: #FFF;
  min-width: 64px;
  align-items: center; }
  .CatalogMenu__Title {
    white-space: nowrap;
    display: flex;
    align-items: center;
    font-size: 17px;
    line-height: 64px;
    margin: 0px 16px; }
  .CatalogMenu__SectionButton {
    flex-wrap: wrap;
    justify-content: center;
    width: 64px !important;
    height: 64px !important;
    padding: 8px 12px !important;
    margin-right: 0 !important; }
    .CatalogMenu__SectionButton *[class*="Icon"] {
      height: 40px;
      width: 40px; }
    .CatalogMenu__SectionButton *[class*="Title"] {
      text-transform: initial;
      margin-top: 2px;
      font-size: 12px;
      line-height: 8px;
      color: #888;
      white-space: nowrap; }
  .CatalogMenu__SectionSelect {
    display: flex; }

.Button {
  position: relative; }
  .Button:last-of-type {
    margin-right: 0; }
  .Button_Iconed {
    cursor: pointer;
    position: relative;
    fill: #0b597a;
    transition: all 0.1s ease-out;
    color: #0b597a;
    display: flex;
    height: 32px;
    margin-right: 16px; }
    .Button_Iconed:hover {
      fill: rgba(11, 89, 122, 0.9); }
  .Button__Icon {
    width: 32px;
    height: 100%;
    pointer-events: none; }
    .Button__Icon_NoTitle {
      width: 100%; }
  .Button__Title {
    padding: 0 8px;
    line-height: 32px;
    white-space: nowrap; }
  .Button_Default {
    cursor: pointer;
    align-self: center;
    background-color: #0b597a;
    color: #FFF;
    border-radius: 4px;
    user-select: none;
    border: none;
    white-space: nowrap;
    display: inline-block;
    transition: all 0.1s ease-out;
    padding: 0 8px;
    font-size: 12px; }
    .Button_Default:hover {
      background-color: rgba(11, 89, 122, 0.9); }
  .Button_Warning {
    cursor: pointer;
    align-self: center;
    background-color: #0b597a;
    color: #FFF;
    border-radius: 4px;
    user-select: none;
    border: none;
    white-space: nowrap;
    display: inline-block;
    transition: all 0.1s ease-out;
    padding: 0 8px;
    font-size: 12px;
    background-color: #f38400; }
    .Button_Warning:hover {
      background-color: rgba(11, 89, 122, 0.9); }
    .Button_Warning:hover {
      background-color: rgba(243, 132, 0, 0.9); }
  .Button_Danger {
    cursor: pointer;
    align-self: center;
    background-color: #0b597a;
    color: #FFF;
    border-radius: 4px;
    user-select: none;
    border: none;
    white-space: nowrap;
    display: inline-block;
    transition: all 0.1s ease-out;
    padding: 0 8px;
    font-size: 12px;
    background-color: #df2100; }
    .Button_Danger:hover {
      background-color: rgba(11, 89, 122, 0.9); }
    .Button_Danger:hover {
      background-color: rgba(223, 33, 0, 0.9); }
  .Button_Unimportant {
    cursor: pointer;
    align-self: center;
    background-color: #0b597a;
    color: #FFF;
    border-radius: 4px;
    user-select: none;
    border: none;
    white-space: nowrap;
    display: inline-block;
    transition: all 0.1s ease-out;
    padding: 0 8px;
    font-size: 12px;
    background-color: #888; }
    .Button_Unimportant:hover {
      background-color: rgba(11, 89, 122, 0.9); }
    .Button_Unimportant:hover {
      background-color: rgba(136, 136, 136, 0.9); }
  .Button_Disabled {
    cursor: pointer;
    align-self: center;
    background-color: #0b597a;
    color: #FFF;
    border-radius: 4px;
    user-select: none;
    border: none;
    white-space: nowrap;
    display: inline-block;
    transition: all 0.1s ease-out;
    padding: 0 8px;
    font-size: 12px;
    cursor: default;
    background-color: #BBB !important; }
    .Button_Disabled:hover {
      background-color: rgba(11, 89, 122, 0.9); }
    .Button_Disabled:hover {
      background-color: #BBB !important; }
  .Button_DisabledIconed {
    cursor: default;
    color: #BBB;
    fill: #BBB; }
    .Button_DisabledIconed:hover {
      color: #BBB;
      fill: #BBB; }
  .Button_ToggledOff {
    cursor: pointer;
    align-self: center;
    background-color: #0b597a;
    color: #FFF;
    border-radius: 4px;
    user-select: none;
    border: none;
    white-space: nowrap;
    display: inline-block;
    transition: all 0.1s ease-out;
    padding: 0 8px;
    font-size: 12px;
    background-color: #BBB !important; }
    .Button_ToggledOff:hover {
      background-color: rgba(11, 89, 122, 0.9); }
  .Button_ToggledOffIconed {
    color: #888;
    fill: #888; }
  .Button__FileSelect {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1; }

.DropDownContainer {
  position: absolute;
  top: 0;
  right: 0; }
  .DropDownContainer__Button {
    width: 32px;
    height: 32px;
    margin: 0 !important; }
  .DropDownContainer__Container {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    padding: 16px;
    background-color: #FFF;
    border-radius: 4px;
    border: 1px solid #0b597a;
    z-index: 3;
    right: 0;
    top: calc(100% + 16px); }
    .DropDownContainer__Container > * {
      margin-right: 0; }
      .DropDownContainer__Container > *:not(:last-of-type) {
        margin-bottom: 8px; }
    .DropDownContainer__Container:before {
      content: '';
      position: absolute;
      top: -8px;
      right: 8px;
      border-bottom: 8px solid #0b597a;
      border-left: 8px solid rgba(255, 255, 255, 0);
      border-right: 8px solid rgba(255, 255, 255, 0); }
    .DropDownContainer__Container:after {
      content: '';
      position: absolute;
      top: -7px;
      right: 8px;
      border-bottom: 8px solid #FFF;
      border-left: 8px solid rgba(255, 255, 255, 0);
      border-right: 8px solid rgba(255, 255, 255, 0); }

.ErrorIndicator {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 16px 0px;
  text-align: center;
  opacity: 0.4; }
  .ErrorIndicator > svg {
    height: 32px;
    width: 32px; }
  .ErrorIndicator__IconLine {
    stroke-linecap: round;
    fill: none;
    stroke: #0b597a;
    stroke-width: 50;
    stroke-miterlimit: 10; }
  .ErrorIndicator__Message {
    text-align: center;
    color: #0b597a; }

.TextFilterInput {
  position: relative;
  margin-bottom: 16px;
  flex: 1 0 auto; }
  .TextFilterInput__ClearButton {
    cursor: pointer;
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 8px;
    height: 32px;
    width: 32px;
    fill: #888; }

.DateFilterInput {
  position: relative;
  margin-bottom: 16px; }
  .DateFilterInput__ClearButton {
    cursor: pointer;
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 8px;
    height: 32px;
    width: 32px;
    fill: #888; }

.Hint {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0; }
  .Hint:hover *[class*='Wrapper'] {
    display: flex;
    pointer-events: none;
    animation: hintFadeIn 600ms ease-out forwards; }
  .Hint__Wrapper {
    display: none;
    position: absolute;
    bottom: 8px;
    width: 240px;
    z-index: 3; }
    .Hint__Wrapper_LeftHint {
      right: calc(50% - 16px - 8px);
      justify-content: flex-end; }
    .Hint__Wrapper_RightHint {
      left: calc(50% - 16px - 8px);
      justify-content: flex-start; }
  .Hint__Body {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    position: absolute;
    letter-spacing: normal;
    font-family: "Roboto", sans-serif;
    font-size: 13px;
    font-weight: lighter;
    padding: 8px 16px;
    color: #FFF;
    top: calc(100% + 16px);
    background-color: #3194be;
    border-radius: 4px;
    line-height: 16px;
    white-space: normal; }
    .Hint__Body:before {
      content: '';
      position: absolute;
      top: -8px;
      border-bottom: 8px solid #3194be;
      border-left: 8px solid rgba(255, 255, 255, 0);
      border-right: 8px solid rgba(255, 255, 255, 0); }
    .Hint__Body_LeftHint:before {
      right: 16px; }
    .Hint__Body_RightHint:before {
      left: 16px; }

.Icon {
  display: inline-block;
  height: 100%;
  width: 100%;
  stroke: #0b171c;
  stroke-width: 0px; }

.InfoBadge {
  white-space: nowrap;
  height: 100%; }
  .InfoBadge_Linked {
    z-index: 1; }
    .InfoBadge_Linked:hover * {
      color: #0b597a !important; }
    .InfoBadge_Linked:hover svg {
      transition: all 0.1s ease-out;
      fill: #0b597a; }
  .InfoBadge__Icon {
    display: inline-block;
    vertical-align: top;
    width: 20px;
    height: 20px;
    margin-top: -2px;
    margin-right: 2px;
    fill: #BBB; }
  .InfoBadge__Text {
    white-space: normal;
    display: inline-block;
    color: #BBB; }

.Input {
  outline: none;
  background-color: #f5f5f5;
  border: 1px solid #0b597a;
  min-width: 48px;
  width: 100%;
  height: 32px;
  line-height: 30px;
  font-size: 13px;
  padding: 0px 8px; }
  .Input::placeholder {
    font-style: italic;
    color: #888;
    text-transform: lowercase; }
  .Input::-ms-clear {
    display: none; }
  .Input::-webkit-clear-button {
    display: none; }
  .Input__ButtonPick {
    display: flex; }
  .Input__ButtonPickData {
    display: flex;
    margin-left: 16px;
    line-height: 32px;
    text-transform: uppercase;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center; }
  .Input__DataCount > b {
    font-weight: bold;
    font-size: 14px;
    margin-left: 8px; }
  .Input__Checkbox {
    position: relative;
    width: 32px;
    height: 32px;
    padding: 8px; }
    .Input__Checkbox:hover .Input__CheckboxMarker {
      opacity: 0.9; }
  .Input__CheckboxInput {
    height: 16px;
    width: 16px;
    border: 1px solid #0b597a;
    background-color: #f5f5f5; }
  .Input__CheckboxHidden {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    opacity: 0;
    height: 100%;
    width: 100%; }
  .Input__CheckboxMarker {
    height: 8px;
    width: 8px;
    margin: 3px;
    background-color: #0b597a;
    visibility: hidden;
    transition: all 0.1s ease-out; }
    .Input__CheckboxMarker_Enabled {
      visibility: visible; }
  .Input__Options {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 100%;
    background-color: #FFF;
    left: 0px;
    right: 0px;
    z-index: 1;
    border-top: 3px solid #0b597a; }
    .Input__Options::-webkit-scrollbar {
      width: 8px; }
    .Input__Options::-webkit-scrollbar-track {
      background-color: rgba(255, 255, 255, 0); }
    .Input__Options::-webkit-scrollbar-thumb {
      background-color: rgba(11, 89, 122, 0.3);
      border-radius: 4px; }
    .Input__Options::-webkit-scrollbar-thumb:hover {
      background-color: #0b597a; }
    .Input__Options::before {
      content: '';
      display: block;
      position: absolute;
      left: 16px;
      border-bottom: 8px solid #0b597a;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      top: -8px; }
  .Input__OptionItem {
    padding: 8px;
    list-style-type: none;
    cursor: pointer; }
    .Input__OptionItem:hover {
      background-color: #e2eef5; }
    .Input__OptionItem_Focused {
      background-color: #e2eef5; }
  .Input__Range {
    display: flex;
    /* Special styling for WebKit/Blink */
    /* All the same stuff for Firefox */
    /* All the same stuff for IE */ }
    .Input__Range input[type=range] {
      -webkit-appearance: none;
      /* Hides the slider so that custom slider can be made */
      border: none;
      padding-left: 0 !important;
      width: 100%;
      /* Specific width is required for Firefox. */
      background: transparent;
      /* Otherwise white in Chrome */ }
    .Input__Range input[type=range]::-webkit-slider-thumb {
      -webkit-appearance: none; }
    .Input__Range input[type=range]:focus {
      outline: none;
      /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */ }
    .Input__Range input[type=range]::-ms-track {
      width: 100%;
      cursor: pointer;
      /* Hides the slider so custom styles can be added */
      background: transparent;
      border-color: transparent;
      color: transparent; }
    .Input__Range input[type=range]::-webkit-slider-thumb {
      cursor: pointer;
      -webkit-appearance: none;
      border: 1px solid #0b597a;
      height: 22px;
      width: 12px;
      margin-top: -10px;
      border-radius: 4px;
      background: #f5f5f5;
      box-shadow: -1px 0px 0px #FFF, 1px 0px 0px #FFF;
      /* Add cool effects to your sliders! */ }
    .Input__Range input[type=range]::-moz-range-thumb {
      cursor: pointer;
      border: 1px solid #0b597a;
      height: 22px;
      width: 12px;
      border-radius: 4px;
      background: #f5f5f5;
      box-shadow: -1px 0px 0px #FFF, 1px 0px 0px #FFF;
      /* Add cool effects to your sliders! */ }
    .Input__Range input[type=range]::-ms-thumb {
      cursor: pointer;
      border: 1px solid #0b597a;
      height: 22px;
      width: 12px;
      margin-top: 0px;
      border-radius: 4px;
      background: #f5f5f5;
      box-shadow: -1px 0px 0px #FFF, 1px 0px 0px #FFF;
      /* Add cool effects to your sliders! */ }
    .Input__Range input[type=range]::-webkit-slider-runnable-track {
      width: 100%;
      height: 2px;
      cursor: pointer;
      background: #0b597a; }
    .Input__Range input[type=range]:focus::-webkit-slider-runnable-track {
      background: #0b597a; }
    .Input__Range input[type=range]::-moz-range-track {
      cursor: pointer;
      width: 100%;
      height: 2px;
      background: #0b597a; }
    .Input__Range input[type=range]::-ms-track {
      cursor: pointer;
      width: 100%;
      height: 2px;
      background: transparent;
      border-color: transparent;
      border-width: 16px 0;
      color: transparent; }
    .Input__Range input[type=range]::-ms-fill-lower {
      background: #0b597a;
      border: 1px solid #0b597a; }
    .Input__Range input[type=range]:focus::-ms-fill-lower {
      background: #0b597a; }
    .Input__Range input[type=range]::-ms-fill-upper {
      background: #0b597a;
      border: 1px solid #0b597a; }
    .Input__Range input[type=range]:focus::-ms-fill-upper {
      background: #0b597a; }
  .Input__RangeValue {
    flex: 0 0 32px;
    font-size: 14px;
    line-height: 32px; }
  .Input__ComplexInputForm [class$=Inputs] {
    padding: 0; }
  .Input__ComplexInputForm .FlexibleScroll {
    margin-bottom: -16px; }
    .Input__ComplexInputForm .FlexibleScroll::before, .Input__ComplexInputForm .FlexibleScroll::after {
      display: none; }

@keyframes animationDash {
  from {
    stroke-dashoffset: 0; }
  to {
    stroke-dashoffset: 1600; } }

.LoadingIndicator {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 16px 0px;
  text-align: center;
  opacity: 0.4; }
  .LoadingIndicator > svg {
    height: 32px;
    width: 32px; }
  .LoadingIndicator__AnimationDash {
    stroke-linecap: round;
    stroke-dasharray: 800;
    stroke-dashoffset: 0;
    fill: none;
    stroke: #0b597a;
    stroke-width: 50;
    stroke-miterlimit: 10;
    animation: animationDash 1.6s linear forwards infinite; }

.RadialProgressBar {
  position: relative;
  height: 20px;
  width: 20px;
  background: #0b597a; }
  .RadialProgressBar__Clipper100 {
    position: absolute;
    top: -50%;
    left: -50%;
    height: 200%;
    width: 100%;
    background: #FFF;
    transform-origin: 100% 50%;
    z-index: 1; }
  .RadialProgressBar__Clipper50 {
    position: absolute;
    top: -50%;
    left: 50%;
    height: 200%;
    width: 100%;
    background: #FFF;
    transform-origin: 0% 50%;
    z-index: 2; }
    .RadialProgressBar__Clipper50_Ready {
      background: #0b597a; }

.TextLink {
  color: #0b597a !important;
  text-decoration: underline;
  display: inline;
  align-self: center; }
  .TextLink:hover {
    text-decoration: none; }
  .TextLink_Dashed {
    text-decoration: none;
    border-bottom: 1px dashed #0b597a; }
  .TextLink_Disabled {
    cursor: default;
    text-decoration: none;
    color: #888 !important;
    border-bottom: none; }

.ConfirmDialog {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999; }
  .ConfirmDialog__Overlay {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0; }
  .ConfirmDialog__WindowDynamicIndent {
    margin-top: 16px;
    flex: 0 1 64px; }
  .ConfirmDialog__Window {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    background-color: #FFF;
    padding: 32px;
    margin-bottom: 16px; }
  .ConfirmDialog__Message {
    text-align: center;
    max-width: 420px; }
  .ConfirmDialog__Buttons {
    text-align: center;
    margin-top: 16px; }
    .ConfirmDialog__Buttons > *:not(:last-of-type) {
      margin-right: 16px; }
  .ConfirmDialog__ButtonsVertical {
    text-align: center;
    flex-direction: column;
    display: flex; }
    .ConfirmDialog__ButtonsVertical > * {
      width: 100%; }
    .ConfirmDialog__ButtonsVertical > *:not(:last-of-type) {
      margin-bottom: 8px; }

.Layout {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%; }
  .Layout__Menu {
    z-index: 10; }
  .Layout__Content {
    flex-grow: 1; }

/* reconf material-ui component to app style */
.MuiPickersToolbar-toolbar {
  background-color: #0b597a !important; }

.MuiButton-textPrimary {
  color: #0b597a !important; }

.timepicker-div {
  background-color: #f5f5f5 !important;
  border: 1px solid #0b597a !important; }
  .timepicker-div button {
    padding: 1px !important; }

.timepicker-ui {
  outline: none !important;
  min-width: 40px !important;
  height: 32px !important;
  line-height: 30px !important;
  font-size: 13px !important;
  padding: 0px 1px 0px 8px !important; }

.LayoutMainMenu {
  background-color: #0b171c;
  height: 100%;
  padding-right: 8px;
  position: relative;
  transition: width 0.1s ease-out;
  user-select: none; }
  .LayoutMainMenu_Collapsed {
    width: 48px; }
  .LayoutMainMenu__Logo {
    position: absolute;
    pointer-events: none;
    height: 34px;
    width: 56px;
    top: 8px;
    right: 8px;
    opacity: 0.3;
    transition: opacity 0.2s ease-out; }
    .LayoutMainMenu__Logo svg {
      fill: #FFF; }
    .LayoutMainMenu__Logo_Hidden {
      transition: opacity 0.05s ease-out;
      opacity: 0; }
  .LayoutMainMenu__Item {
    height: 48px; }
  .LayoutMainMenu__Collapse {
    cursor: pointer;
    height: 48px;
    width: 48px;
    padding: 8px; }
    .LayoutMainMenu__Collapse svg {
      fill: #FFF;
      transform: rotate(180deg);
      transition: transform 0.1s ease-out; }
    .LayoutMainMenu__Collapse_On svg {
      transform: rotate(0deg); }

.LayoutMainMenuItem {
  position: relative;
  white-space: nowrap;
  height: 48px;
  min-width: 48px; }
  .LayoutMainMenuItem > *[class*="Hint"] {
    display: none;
    user-select: none; }
  .LayoutMainMenuItem:hover > *[class*="Hint"] {
    display: flex;
    animation: hintFadeIn 1000ms ease-out forwards; }
  .LayoutMainMenuItem__Link {
    position: absolute;
    height: 100%;
    width: 100%; }
  .LayoutMainMenuItem__Body {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden; }
    .LayoutMainMenuItem__Body_Disabled {
      opacity: 0.2; }
  .LayoutMainMenuItem__Icon {
    flex: 0 0 auto;
    width: 48px;
    height: 48px;
    padding: 10px;
    float: left; }
    .LayoutMainMenuItem__Icon svg {
      fill: #FFF; }
  .LayoutMainMenuItem__Text {
    flex: 0 0 auto;
    text-overflow: ellipsis;
    text-decoration: none;
    overflow: hidden;
    color: #FFF;
    padding-right: 16px;
    height: 48px;
    font: normal 13px/48px "Roboto", sans-serif;
    text-transform: uppercase; }
  .LayoutMainMenuItem__HintWrapper {
    width: 300px;
    white-space: normal;
    position: absolute;
    left: calc(100% + 8px);
    top: 8px; }
    .LayoutMainMenuItem__HintWrapper b {
      font-size: 1.2em;
      font-weight: 500; }
  .LayoutMainMenuItem__Hint {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    pointer-events: none;
    position: relative;
    align-items: center;
    padding: 8px 16px;
    background: #415963;
    font: lighter 12px "Roboto", sans-serif;
    line-height: 16px;
    color: #FFF;
    border-radius: 4px;
    border: 1px solid #FFF;
    max-width: 600px;
    min-height: 32px; }
    .LayoutMainMenuItem__Hint:after {
      content: '';
      position: absolute;
      right: 100%;
      top: 8px;
      border-right: 8px solid #415963;
      border-top: 8px solid rgba(255, 255, 255, 0);
      border-bottom: 8px solid rgba(255, 255, 255, 0); }
    .LayoutMainMenuItem__Hint:before {
      content: '';
      position: absolute;
      right: 100%;
      top: 6px;
      border-right: 9px solid #FFF;
      border-top: 10px solid rgba(255, 255, 255, 0);
      border-bottom: 10px solid rgba(255, 255, 255, 0); }

.Manifest {
  height: 100%;
  position: relative; }
  .Manifest__LoadingIndicator {
    height: 32px;
    width: 32px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }
  .Manifest__Map {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 1;
    background-image: url("../img/map_grid.gif"); }
  .Manifest__Header {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 16px;
    left: 16px;
    right: 16px;
    z-index: 3;
    pointer-events: none; }
  .Manifest__HeaderLeft {
    display: flex;
    flex: 0 0 auto; }
  .Manifest__HeaderRight {
    display: flex;
    align-items: center;
    flex-wrap: wrap-reverse;
    padding-left: 16px;
    flex: 1 1 auto;
    justify-content: flex-end; }
  .Manifest__Body {
    position: absolute;
    top: 96px;
    left: 16px;
    right: 16px;
    bottom: 16px;
    z-index: 2;
    pointer-events: none; }
  .Manifest__Logo {
    position: absolute;
    opacity: 0.55;
    height: 48px;
    width: 96px;
    bottom: 8px;
    margin-left: 8px;
    z-index: 2; }
  .Manifest__Tabs {
    display: flex;
    height: 100%;
    flex-direction: row; }
  .Manifest__LeftTabDynamicIndent {
    flex: 0 1 48px; }
  .Manifest__RightTabDynamicIndent {
    flex: 0 1 48px; }
  .Manifest__RightTabTopIndent {
    flex: 0 0 auto;
    height: calc(200px - 12vw);
    max-height: 48px; }
  .Manifest__LeftTab {
    flex-direction: column;
    flex: 0 0 480px;
    display: flex; }
  .Manifest__RightTab {
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 480px;
    padding-left: 16px;
    display: flex; }

.ManifestControls {
  height: 64px;
  flex: 0 0 auto;
  display: flex;
  pointer-events: auto; }
  .ManifestControls__SectionButton {
    flex-wrap: wrap;
    justify-content: center;
    width: 64px !important;
    height: 64px !important;
    padding: 8px 12px !important;
    margin-right: 0 !important; }
    .ManifestControls__SectionButton *[class*="Icon"] {
      height: 40px;
      width: 40px; }
    .ManifestControls__SectionButton *[class*="Title"] {
      text-transform: initial;
      margin-top: 2px;
      font-size: 12px;
      line-height: 8px;
      color: #888;
      white-space: nowrap; }
  .ManifestControls__InfoBlock {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    position: relative;
    background-color: #FFF;
    display: flex;
    min-width: 64px;
    align-items: center; }
  .ManifestControls__AddButton {
    cursor: pointer;
    align-self: center;
    background-color: #0b597a;
    color: #FFF;
    border-radius: 4px;
    user-select: none;
    border: none;
    white-space: nowrap;
    display: inline-block;
    transition: all 0.1s ease-out;
    padding: 0 8px;
    font-size: 12px;
    color: #FFF !important;
    width: 38px;
    height: 38px;
    fill: #FFF; }
    .ManifestControls__AddButton:hover {
      background-color: rgba(11, 89, 122, 0.9); }
    .ManifestControls__AddButton:hover {
      fill: #FFF; }
  .ManifestControls__Manifest {
    padding: 8px 12px; }
  .ManifestControls__ManifestHint {
    text-transform: initial;
    margin-top: 5px;
    font-size: 12px;
    line-height: 8px;
    text-align: center;
    color: #888; }
  .ManifestControls__ManifestBar {
    display: flex; }
  .ManifestControls__ManifestTitle {
    white-space: nowrap;
    display: flex;
    align-items: center;
    font-size: 17px; }
  .ManifestControls__Name {
    max-width: 30vw;
    margin-right: 8px;
    overflow: hidden;
    text-overflow: ellipsis; }
  .ManifestControls__TabSelect {
    display: flex;
    margin: 0 8px; }
  .ManifestControls__RunButton {
    cursor: pointer;
    align-self: center;
    background-color: #0b597a;
    color: #FFF;
    border-radius: 4px;
    user-select: none;
    border: none;
    white-space: nowrap;
    display: inline-block;
    transition: all 0.1s ease-out;
    padding: 0 8px;
    font-size: 12px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    height: 56px;
    width: 56px;
    border-radius: 32px;
    border: 2px solid #FFF;
    margin-left: 16px;
    position: relative;
    padding: 12px;
    fill: #FFF; }
    .ManifestControls__RunButton:hover {
      background-color: rgba(11, 89, 122, 0.9); }
    .ManifestControls__RunButton:hover {
      fill: #FFF; }
  .ManifestControls__MiniStatus {
    position: absolute;
    right: -54px;
    top: 42px;
    height: 0px;
    width: 0px;
    z-index: 5; }

.ManifestFirst {
  background-image: url("../img/map_grid.gif");
  width: 100%;
  height: 100%;
  padding: 16px; }
  .ManifestFirst__Window {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    background-color: #FFF;
    padding: 16px;
    width: 400px;
    pointer-events: auto; }
  .ManifestFirst__Message {
    text-align: center;
    width: 280px;
    margin: auto;
    margin-bottom: 8px;
    color: #888; }

.ManifestForm {
  flex-grow: 1; }
  .ManifestForm__Title {
    margin-bottom: 16px; }
  .ManifestForm__Submit {
    text-align: center; }

.ManifestMiniStatus {
  width: 24px;
  height: 24px;
  user-select: none; }
  .ManifestMiniStatus__Icon {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    position: absolute;
    fill: #FFF;
    border: 2px solid #FFF;
    height: 24px;
    width: 24px;
    border-radius: 12px;
    background-color: #FFF;
    overflow: hidden;
    left: 0px;
    top: 0px;
    z-index: -1; }
    .ManifestMiniStatus__Icon_Success {
      background-color: #00bb00; }
    .ManifestMiniStatus__Icon_Warning {
      background-color: #f28300; }
    .ManifestMiniStatus__Icon_Enqueued {
      background-color: #f28300; }
    .ManifestMiniStatus__Icon_Error {
      background-color: #df2100; }
  .ManifestMiniStatus__Message {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    position: absolute;
    background-color: #FFF;
    text-transform: lowercase;
    height: 24px;
    line-height: 24px;
    white-space: nowrap;
    border-radius: 0px 12px 12px 0px;
    left: 12px;
    top: 0px;
    z-index: -2;
    opacity: 0;
    width: 0%;
    transition: all 0.2s; }
  .ManifestMiniStatus:hover .ManifestMiniStatus__Message {
    opacity: 1;
    width: auto;
    padding-left: 16px;
    padding-right: 12px; }

.ManifestTab {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  position: relative;
  max-width: 480px;
  padding: 16px;
  padding-top: 48px;
  background-color: #FFF;
  flex: 0 1 auto;
  display: flex;
  overflow: hidden;
  pointer-events: auto;
  margin-bottom: 8px; }
  .ManifestTab__Head {
    display: flex;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px; }
  .ManifestTab__Body {
    display: flex;
    flex: 1 1 auto; }
    .ManifestTab__Body > * {
      display: flex;
      flex: 1 1 auto;
      flex-direction: column; }
  .ManifestTab__Title {
    height: 16px;
    flex: 1 1 auto;
    margin: 16px 0px;
    line-height: 16px;
    font-family: "Roboto", sans-serif;
    font-weight: lighter;
    font-size: 17px;
    text-transform: uppercase;
    color: #888; }
  .ManifestTab__BackButton {
    cursor: pointer;
    fill: #888;
    padding: 14px;
    height: 48px;
    width: 48px;
    margin-right: 0; }
  .ManifestTab__TitleIndent {
    margin-right: 16px; }
  .ManifestTab__CloseButton {
    cursor: pointer;
    flex: 0 0 auto;
    fill: #888;
    padding: 14px;
    height: 48px;
    width: 48px;
    margin-right: 0; }

.ManifestUserForms {
  margin-top: 16px;
  margin-right: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end; }
  .ManifestUserForms__UserButton {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    pointer-events: initial;
    border-radius: 16px;
    background-color: #FFF;
    padding-left: 8px;
    margin-bottom: 16px;
    width: 32px;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    justify-content: flex-end; }
    .ManifestUserForms__UserButton:hover {
      width: auto; }

.SubscriptionNotification {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  display: flex;
  max-width: 30vw;
  pointer-events: auto;
  align-self: right;
  position: relative;
  padding: 0px 16px;
  margin-top: 5px;
  line-height: 21.33333px;
  border-radius: 16px 16px;
  background-color: #FFF; }
  .SubscriptionNotification_Critical {
    background-color: #df2100;
    color: #FFF;
    font-weight: bold; }
  .SubscriptionNotification__CloseButton {
    cursor: pointer;
    flex: 0 0 auto;
    fill: #888;
    padding: 14px;
    height: 48px;
    width: 48px;
    margin-right: 0; }

.LeafletMap {
  height: 100%; }
  .LeafletMap > * {
    height: 100%; }
  .LeafletMap__Cluster {
    border: none;
    background: none; }
  .LeafletMap__Map {
    opacity: 1; }
    .LeafletMap__Map_Transparent {
      opacity: 0.4 !important; }
  .LeafletMap__ClusterBody {
    position: absolute;
    border: none;
    height: 22px;
    line-height: 22px;
    width: 22px;
    background-color: #FFF;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
    top: 11%;
    border-radius: 4px;
    font-weight: bold;
    color: #555;
    font-size: 12px;
    text-align: center; }
    .LeafletMap__ClusterBody > span {
      display: inline-block;
      height: 100%; }
    .LeafletMap__ClusterBody::first-letter {
      font-size: 10px; }
  .LeafletMap__PolylineSelectArea {
    stroke-linecap: round;
    stroke-width: 20px;
    stroke: transparent;
    fill: none; }
  .LeafletMap__Polyline {
    stroke-dasharray: 2px 12px;
    stroke-linecap: round;
    stroke-width: 2px;
    fill: none; }
    .LeafletMap__Polyline_Path {
      stroke-dasharray: none; }
    .LeafletMap__Polyline_Track {
      cursor: grab;
      stroke-dasharray: none;
      stroke-dasharray: 8px;
      animation: track_direction 1.6s linear forwards infinite; }

@keyframes track_direction {
  from {
    stroke-dashoffset: 0; }
  to {
    stroke-dashoffset: -16px; } }
    .LeafletMap__Polyline_Dimmed {
      display: none;
      pointer-events: none; }
  .LeafletMap__PolylineShadow {
    stroke-dasharray: 2px 12px;
    stroke-linecap: round;
    stroke-width: 3.4px;
    stroke: #333;
    stroke-opacity: 0.9;
    fill: none; }
    .LeafletMap__PolylineShadow_Path {
      stroke-dasharray: none; }
    .LeafletMap__PolylineShadow_Track {
      stroke: #FFF;
      stroke-dasharray: none;
      stroke-width: 4.4px;
      cursor: grab; }
    .LeafletMap__PolylineShadow_Dimmed {
      display: none; }
  .LeafletMap__PolylineConnectingPoints {
    stroke-width: 3;
    stroke: #0b597a;
    stroke-opacity: 1; }
  .LeafletMap__ZoneGeometryShadow {
    stroke-linecap: round;
    stroke-width: 5px;
    stroke: #333;
    stroke-opacity: 0.9;
    fill: none; }
  .LeafletMap__ZoneGeometryBody {
    stroke-width: 4px;
    stroke: #FFF;
    fill: rgba(11, 89, 122, 0.6); }
  .LeafletMap__ZoneGeometryColor {
    stroke-width: 2px;
    stroke-dasharray: 0px 3px;
    fill: none; }

.sr-only {
  display: none; }

.leaflet-container {
  background-image: url("../img/map_grid.gif"); }

.leaflet-draw, .leaflet-draw-tooltip {
  display: none; }

path[fill-rule="evenodd"]:not([class~="leaflet-zonepart"]), .leaflet-interactive .leaflet-drag-target {
  stroke-width: 3;
  stroke: #0b597a;
  stroke-dasharray: 0px 6px;
  stroke-opacity: 1;
  fill: #0b597a;
  fill-opacity: 0.1; }

.leaflet-draw-guides > * {
  opacity: 1;
  height: 3px;
  width: 3px;
  border-radius: 2px;
  background-color: #0b597a !important; }

.leaflet-editing-icon {
  border: 2px solid #0b597a;
  border-radius: 6px;
  width: 12px !important;
  height: 12px !important;
  margin-left: -6px !important;
  margin-top: -6px !important;
  z-index: 9999 !important; }

.LeafletMapControls {
  display: flex;
  align-items: center;
  flex-wrap: wrap-reverse;
  justify-content: flex-end; }
  .LeafletMapControls__Block {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    border-radius: 24px;
    padding: 0px 8px;
    background-color: #FFF;
    margin: 8px 0px;
    margin-left: 16px;
    pointer-events: auto;
    flex-wrap: wrap;
    min-width: 210px; }
  .LeafletMapControls__Button {
    height: 48px;
    width: 48px;
    margin-right: 0;
    padding: 12px; }

.LeafletMapMarker {
  border: none;
  fill: transparent; }
  .LeafletMapMarker__Body {
    position: absolute;
    height: 22px;
    width: 22px;
    left: 5px;
    top: 8px;
    border-radius: 4px;
    border: 1px solid #0b171c;
    color: #FFF;
    line-height: 21px;
    text-align: center;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.6), -1px 1px 1px rgba(0, 0, 0, 0.4), 1px -1px 1px rgba(0, 0, 0, 0.4), -1px -1px 1px rgba(0, 0, 0, 0.2); }
    .LeafletMapMarker__Body > * {
      display: inline-block;
      height: 100%; }
    .LeafletMapMarker__Body svg {
      fill: #FFF;
      stroke: #0b171c;
      stroke-width: 8; }
    .LeafletMapMarker__Body_Loading {
      opacity: 0.3; }
    .LeafletMapMarker__Body_Selected {
      height: 30px;
      width: 30px;
      transform: rotate(-45deg);
      line-height: 28px;
      top: -20% !important;
      left: 4% !important;
      box-shadow: 0px 0px 15px #FFF, 0px 0px 20px #FFF, 0px 0px 25px #FFF; }
      .LeafletMapMarker__Body_Selected > span {
        transform: rotate(45deg); }
    .LeafletMapMarker__Body_Dimmed {
      opacity: 0.2; }
    .LeafletMapMarker__Body_Base {
      height: 30px;
      width: 30px;
      fill: #FFF;
      top: -1px;
      left: -3px;
      padding: 3px;
      background: #0b171c; }
      .LeafletMapMarker__Body_Base svg {
        stroke-width: 0; }
    .LeafletMapMarker__Body_Missed {
      height: 30px;
      width: 30px;
      top: -1px;
      left: -3px;
      padding: 2px;
      background-color: #df2100;
      border: 2px solid #0b171c; }
      .LeafletMapMarker__Body_Missed svg {
        fill: #FFF;
        stroke: #0b171c;
        stroke-width: 10; }
    .LeafletMapMarker__Body_Warning {
      border: 1px solid #df2100; }
    .LeafletMapMarker__Body_Big {
      height: 30px;
      width: 30px;
      top: -1px;
      left: -3px;
      padding: 2px; }
  .LeafletMapMarker__VisitedStatus {
    border-radius: 6px;
    position: absolute;
    width: 12px;
    height: 12px;
    top: -7px;
    right: -7px;
    border: 1px solid #0b171c;
    transform: rotate(45deg);
    display: none; }
    .LeafletMapMarker__VisitedStatus_Success {
      display: block;
      background-color: #f38400; }
    .LeafletMapMarker__VisitedStatus_Success {
      display: block;
      background-color: #00bb00; }
    .LeafletMapMarker__VisitedStatus_Failed {
      display: block;
      background-color: #df2100; }
  .LeafletMapMarker__Beak {
    position: absolute;
    top: 27px;
    left: 17px;
    transform: rotate(15deg);
    border-top: 16px solid #0b171c;
    border-right: 7px solid transparent; }
    .LeafletMapMarker__Beak_Selected {
      border-top: 35px solid #0b171c;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      left: -14%;
      top: 17%;
      transform: none; }
    .LeafletMapMarker__Beak_Dimmed {
      opacity: 0.2; }
    .LeafletMapMarker__Beak_Loading {
      opacity: 0.3; }
    .LeafletMapMarker__Beak_Warning {
      border-top: 16px solid #df2100; }
  .LeafletMapMarker__Move {
    position: absolute;
    left: 10px;
    top: -3px; }
  .LeafletMapMarker__MoveArrow {
    position: absolute;
    transform-origin: 50% 100%;
    height: 17px;
    width: 8px; }
    .LeafletMapMarker__MoveArrow::after {
      content: '';
      display: block;
      border-bottom: 4px solid #0b171c;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent; }
    .LeafletMapMarker__MoveArrow_Base::after {
      border-bottom-color: #FFF; }
    .LeafletMapMarker__MoveArrow_Rotate45 {
      transform: rotate(45deg); }
    .LeafletMapMarker__MoveArrow_Rotate135 {
      transform: rotate(135deg); }
    .LeafletMapMarker__MoveArrow_Rotate225 {
      transform: rotate(225deg); }
    .LeafletMapMarker__MoveArrow_Rotate315 {
      transform: rotate(315deg); }
  .LeafletMapMarker__BeakShadow {
    position: absolute;
    height: 3px;
    width: 5px;
    border-radius: 3px/2px;
    box-shadow: -4px 0 6px #000, 4px 0 6px #000, -2px 0 6px #000, 2px 0 6px #000, 0 0 4px #000, 0 0 4px #000;
    background: rgba(0, 0, 0, 0.8);
    top: 38px;
    left: 13px;
    opacity: 0.3; }

.LeafletMapNoPoints {
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }
  .LeafletMapNoPoints__Window {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 16px;
    margin: 16px;
    background: #FFF; }
  .LeafletMapNoPoints__Smile {
    fill: #0b597a;
    opacity: 0.4;
    width: 32px;
    height: 32px;
    margin-bottom: 8px; }
  .LeafletMapNoPoints__Message {
    margin-bottom: 16px;
    max-width: 270px;
    text-align: center; }
  .LeafletMapNoPoints__Buttons {
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding: 0 16px; }

.LeafletMapTrackMarker {
  border: none;
  fill: transparent; }
  .LeafletMapTrackMarker__Icon {
    stroke: #333;
    stroke-width: 6px; }
  .LeafletMapTrackMarker__Body {
    position: absolute;
    height: 32px;
    width: 32px;
    padding: 3px;
    background-color: #FFF;
    border-radius: 16px;
    border: 1px solid #0b171c; }
    .LeafletMapTrackMarker__Body > * {
      display: inline-block;
      height: 100%; }
  .LeafletMapTrackMarker__Beak {
    position: absolute;
    top: 27px;
    left: 17px;
    transform: rotate(15deg);
    border-top: 16px solid #0b171c;
    border-right: 7px solid transparent; }
  .LeafletMapTrackMarker__BeakShadow {
    position: absolute;
    height: 3px;
    width: 5px;
    border-radius: 3px/2px;
    box-shadow: -4px 0 6px #000, 4px 0 6px #000, -2px 0 6px #000, 2px 0 6px #000, 0 0 4px #000, 0 0 4px #000;
    background: rgba(0, 0, 0, 0.8);
    top: 38px;
    left: 13px;
    opacity: 0.3; }
  .LeafletMapTrackMarker__Time {
    position: absolute;
    top: -20px;
    left: -5px;
    border-radius: 4px;
    font-size: 12px;
    background-color: #FFF;
    border: 1px solid #0b171c;
    width: 42px;
    text-align: center;
    font-weight: bold;
    height: 17px;
    line-height: 17px;
    padding: 0px 4px; }

.DataBadge {
  display: flex;
  position: relative;
  height: 32px;
  line-height: 32px; }
  .DataBadge__Caption {
    margin-right: 8px; }
  .DataBadge__Value {
    font-weight: bold;
    font-size: 14px; }

.DaysSelector__Submit {
  margin-top: 16px;
  text-align: center; }

.DayPicker-Day--selected {
  background-color: #0b597a !important; }

.DayPicker-Day--today {
  color: #ff6c37 !important; }

.DeleteList__Controls {
  position: absolute;
  display: block; }
  .DeleteList__Controls > * {
    margin-right: 16px; }

.DeleteList__List {
  margin-top: 48px; }

.FlexibleScroll {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: auto; }
  .FlexibleScroll:before {
    content: '';
    position: absolute;
    right: 16px;
    left: 0px;
    height: 8px;
    background: linear-gradient(#FFF, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
    z-index: 1; }
  .FlexibleScroll:after {
    content: '';
    position: absolute;
    right: 16px;
    left: 0px;
    bottom: 0px;
    height: 8px;
    background: linear-gradient(to top, #FFF, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
    z-index: 1; }
  .FlexibleScroll__Scroller {
    overflow-y: auto;
    overflow-x: hidden;
    display: flex; }
    .FlexibleScroll__Scroller::-webkit-scrollbar {
      width: 8px; }
    .FlexibleScroll__Scroller::-webkit-scrollbar-track {
      background-color: rgba(255, 255, 255, 0); }
    .FlexibleScroll__Scroller::-webkit-scrollbar-thumb {
      background-color: rgba(11, 89, 122, 0.3);
      border-radius: 4px; }
    .FlexibleScroll__Scroller::-webkit-scrollbar-thumb:hover {
      background-color: #0b597a; }

.FormSwitcher {
  display: flex;
  flex: 1 0 auto;
  height: 16px;
  justify-content: space-between;
  padding: 0 48px;
  margin-bottom: 16px;
  flex-direction: row; }
  .FormSwitcher + * {
    max-height: calc(100% - 32px) !important; }

.FormWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  .FormWrapper > * {
    display: flex; }

.IncrementalList {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: auto;
  align-self: normal; }
  .IncrementalList:before {
    content: '';
    position: absolute;
    right: 16px;
    left: 0px;
    height: 8px;
    background: linear-gradient(#FFF, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
    z-index: 1; }
  .IncrementalList:after {
    content: '';
    position: absolute;
    right: 16px;
    left: 0px;
    bottom: 0px;
    height: 8px;
    background: linear-gradient(to top, #FFF, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
    z-index: 1; }
  .IncrementalList__List {
    margin-top: 16px; }
    .IncrementalList__List > li {
      animation-name: fadeIn;
      animation-fill-mode: both;
      animation-duration: .3s; }
    .IncrementalList__List > li:nth-of-type(1) {
      animation-delay: 0.23333s; }
    .IncrementalList__List > li:nth-of-type(2) {
      animation-delay: 0.26667s; }
    .IncrementalList__List > li:nth-of-type(3) {
      animation-delay: 0.3s; }
    .IncrementalList__List > li:nth-of-type(4) {
      animation-delay: 0.33333s; }
    .IncrementalList__List > li:nth-of-type(5) {
      animation-delay: 0.36667s; }
    .IncrementalList__List > li:nth-of-type(6) {
      animation-delay: 0.4s; }
    .IncrementalList__List > li:nth-of-type(7) {
      animation-delay: 0.43333s; }
    .IncrementalList__List > li:nth-of-type(8) {
      animation-delay: 0.46667s; }
    .IncrementalList__List > li:nth-of-type(9) {
      animation-delay: 0.5s; }
    .IncrementalList__List > li:nth-of-type(10) {
      animation-delay: 0.53333s; }
    .IncrementalList__List > li:nth-of-type(11) {
      animation-delay: 0.56667s; }
    .IncrementalList__List > li:nth-of-type(12) {
      animation-delay: 0.6s; }
    .IncrementalList__List > li:nth-of-type(13) {
      animation-delay: 0.63333s; }
    .IncrementalList__List > li:nth-of-type(14) {
      animation-delay: 0.66667s; }
    .IncrementalList__List > li:nth-of-type(15) {
      animation-delay: 0.7s; }
    .IncrementalList__List > li:nth-of-type(16) {
      animation-delay: 0.73333s; }
    .IncrementalList__List > li:nth-of-type(17) {
      animation-delay: 0.76667s; }
    .IncrementalList__List > li:nth-of-type(18) {
      animation-delay: 0.8s; }
    .IncrementalList__List > li:nth-of-type(19) {
      animation-delay: 0.83333s; }
    .IncrementalList__List > li:nth-of-type(20) {
      animation-delay: 0.86667s; }
    .IncrementalList__List > div:last-of-type:after {
      border: none !important; }
    .IncrementalList__List > li {
      border-left: 1px solid rgba(255, 255, 255, 0);
      cursor: pointer;
      margin-bottom: 16px;
      position: relative; }
      .IncrementalList__List > li:after {
        content: '';
        pointer-events: none;
        position: absolute;
        cursor: auto;
        bottom: -8px;
        left: 16px;
        right: 16px;
        border-bottom: 1px solid #EEE; }
      .IncrementalList__List > li:hover {
        user-select: none;
        z-index: 2; }
        .IncrementalList__List > li:hover a {
          background-color: rgba(255, 255, 255, 0.25); }
  .IncrementalList__ScrollBox {
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 24px; }
    .IncrementalList__ScrollBox::-webkit-scrollbar {
      width: 8px; }
    .IncrementalList__ScrollBox::-webkit-scrollbar-track {
      background-color: rgba(255, 255, 255, 0); }
    .IncrementalList__ScrollBox::-webkit-scrollbar-thumb {
      background-color: rgba(11, 89, 122, 0.3);
      border-radius: 4px; }
    .IncrementalList__ScrollBox::-webkit-scrollbar-thumb:hover {
      background-color: #0b597a; }
  .IncrementalList__NoItems {
    color: #888;
    text-align: center;
    padding: 16px 0; }

.InfoText {
  font-size: 14px;
  min-width: 400px;
  display: block !important; }
  .InfoText a {
    color: #0b597a;
    text-decoration: underline; }

.ItemForm {
  display: flex;
  max-height: 100%;
  flex-direction: column; }
  .ItemForm__Inputs {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 8px 16px; }
  .ItemForm__Submit {
    width: 100%;
    display: flex;
    flex-shrink: 0;
    margin: 8px 0px;
    justify-content: space-around; }
  .ItemForm__InputsSubmit {
    flex: 1 0 auto;
    padding-left: 16px;
    margin-bottom: 16px;
    display: flex; }
    .ItemForm__InputsSubmit > * {
      align-self: flex-end; }
  .ItemForm__Input {
    padding-bottom: 16px;
    display: inline-block;
    flex: 0 0 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative; }
    .ItemForm__Input_Time {
      flex-basis: 21%; }
    .ItemForm__Input_Number {
      flex-basis: 21%; }
    .ItemForm__Input_Hidden {
      display: none; }
    .ItemForm__Input_Checkbox {
      flex-basis: 47.5%;
      flex-direction: row-reverse;
      align-items: center;
      height: 48px;
      align-self: center; }
      .ItemForm__Input_Checkbox > [class$='InputCaption'] {
        margin-bottom: 0px;
        margin-left: 0px; }
      .ItemForm__Input_Checkbox > input {
        height: 16px;
        width: 16px;
        min-width: 16px;
        margin-right: 8px; }
    .ItemForm__Input_Coords {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between; }
      .ItemForm__Input_Coords > *[class$='InputCaption'] {
        position: absolute;
        left: 48px; }
      .ItemForm__Input_Coords label {
        flex: 0 0 47.5%;
        margin-bottom: 8px; }
      .ItemForm__Input_Coords input {
        flex: 0 0 47.5%; }
    .ItemForm__Input_Size_1 {
      flex-basis: 21%; }
    .ItemForm__Input_Size_2 {
      flex-basis: 47.5%; }
    .ItemForm__Input_Size_3 {
      flex-basis: 73.7%; }
    .ItemForm__Input_Size_4 {
      flex-basis: 100%; }
    .ItemForm__Input_Invalid > input, .ItemForm__Input_Invalid > select, .ItemForm__Input_Invalid > textarea {
      border: 2px solid #df2100 !important; }
    .ItemForm__Input_Invalid *[class*='HelpButton'] {
      fill: #df2100 !important;
      opacity: 1 !important; }
    .ItemForm__Input_Invalid *[class$='ButtonPickData'] {
      color: #df2100; }
  .ItemForm__InputCaption {
    margin-bottom: 8px;
    display: flex;
    align-items: center; }
  .ItemForm__HelpButton {
    opacity: 0.4;
    height: 16px;
    width: 16px;
    margin-left: 8px;
    flex-shrink: 0; }
    .ItemForm__HelpButton:hover {
      opacity: 1; }
    .ItemForm__HelpButton_Opened {
      opacity: 1 !important;
      height: 22px;
      width: 22px; }

.ItemSelectionList {
  min-width: 448px; }
  .ItemSelectionList__Submit {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    flex: 0 0 auto; }
  .ItemSelectionList__MassSelection {
    margin-bottom: -12px; }
    .ItemSelectionList__MassSelection > * {
      margin-right: 16px; }
  .ItemSelectionList__Groups {
    padding-top: 8px;
    display: flex;
    flex-direction: column;
    max-height: 280px; }

.ListItem {
  display: flex;
  position: relative;
  flex-shrink: 0;
  align-items: center; }
  .ListItem:hover .ListItem__Controls {
    display: inline-block; }
  .ListItem__ListNumber {
    color: #BBB;
    font-size: 12px;
    min-width: 16px;
    text-align: center; }
  .ListItem_Current::before {
    content: '';
    border-left: 8px solid #0b597a;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent; }
  .ListItem__WaybillStatusIcon {
    height: 22px;
    width: 22px;
    position: absolute;
    left: 28px;
    top: 56%;
    stroke: #FFF;
    stroke-width: 12px; }
    .ListItem__WaybillStatusIcon_Pending {
      fill: #f28300; }
    .ListItem__WaybillStatusIcon_Ok {
      fill: #00bb00; }
    .ListItem__WaybillStatusIcon_Reject {
      fill: #df2100; }
  .ListItem__Number {
    display: flex;
    align-items: center;
    margin-left: 8px;
    flex-basis: 48px;
    flex: 0 0 auto; }
  .ListItem__NumberBody {
    flex-shrink: 0;
    height: 24px;
    width: 24px;
    border-radius: 4px;
    margin: 6px;
    border: 1px solid #0b171c;
    color: #FFF;
    line-height: 21px;
    text-align: center;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.6), -1px 1px 1px rgba(0, 0, 0, 0.4), 1px -1px 1px rgba(0, 0, 0, 0.4), -1px -1px 1px rgba(0, 0, 0, 0.2); }
  .ListItem__Icon {
    height: 48px;
    width: 48px;
    flex-basis: 48px;
    flex: 0 0 auto;
    padding: 6px; }
  .ListItem__Link {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1; }
  .ListItem__Title {
    margin: 4px 0; }
  .ListItem__TitleMain {
    display: inline; }
  .ListItem__TitleExtra {
    display: inline;
    color: #777;
    font-style: italic;
    margin-left: 8px; }
  .ListItem__Details {
    margin: 4px 0px 6px 0px;
    color: #777;
    font-style: italic; }
  .ListItem__Info {
    padding-left: 8px; }
  .ListItem__InfoBadges {
    display: flex;
    min-height: 24px; }
    .ListItem__InfoBadges > div:not(:last-of-type) {
      margin-right: 12px; }
  .ListItem__Controls {
    display: none;
    position: absolute;
    right: 8px;
    top: 8px;
    background: #FFF;
    padding-left: 8px;
    height: 24px;
    letter-spacing: 8px;
    white-space: nowrap;
    box-shadow: 0px 0px 16px #FFF, 0px 0px 16px #FFF, 0px 0px 16px #FFF;
    z-index: 2; }
    .ListItem__Controls .Button {
      display: inline-block;
      opacity: 0.5;
      height: 24px;
      width: 24px;
      margin-right: 8px;
      transition: all 0.1s ease-out;
      fill: #0b597a; }
      .ListItem__Controls .Button:hover {
        opacity: 1; }
    .ListItem__Controls_AlwaysShow {
      display: block; }

.ListSection__Controls {
  margin-bottom: 32px;
  display: flex;
  flex-shrink: 0;
  position: relative; }
  .ListSection__Controls:after {
    content: '';
    pointer-events: none;
    position: absolute;
    cursor: auto;
    bottom: -16px;
    border-bottom: 1px solid #EEE;
    width: 100%; }

.ListSection__Button:not(:last-of-type) {
  margin-right: 16px; }

.ListSection__Button_Iconed {
  width: 32px; }

.ListSection__Button b {
  font-size: 17px;
  margin-right: 8px;
  line-height: 14px;
  vertical-align: text-bottom;
  margin-left: -4px; }

.PhotoGallery__Image {
  position: relative;
  cursor: pointer;
  margin: 16px 0px;
  border: 1px solid #0b597a;
  border-radius: 4px;
  overflow: hidden;
  min-height: 48px;
  max-height: 400px;
  display: flex; }
  .PhotoGallery__Image img {
    display: block; }
  .PhotoGallery__Image:not(:hover) [class$='ZoomSubstrate'] {
    opacity: 0; }

.PhotoGallery__ZoomSubstrate {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  background-color: rgba(11, 89, 122, 0.35);
  opacity: 1;
  transition: all 0.1s ease-out; }
  .PhotoGallery__ZoomSubstrate > svg {
    fill: #FFF;
    height: 64px;
    width: 64px; }

.PhotoGallery__Selector {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }

.PhotoGallery__Link {
  font-weight: bold;
  font-size: 14px;
  margin: 0px 8px; }

.SelectableListItem {
  display: flex; }
  .SelectableListItem__CheckButton {
    display: flex;
    align-items: center;
    margin-right: 8px; }
  .SelectableListItem__ListItem {
    flex-grow: 1;
    pointer-events: none; }

.TabTitle {
  height: 16px;
  flex: 1 0 auto;
  margin-bottom: 16px;
  line-height: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: lighter;
  font-size: 17px;
  text-transform: uppercase;
  color: #888;
  white-space: nowrap; }

.CourierView__WaybillTitle {
  margin-bottom: 8px; }

.ReportForm__SelectFormat {
  padding: 0 16px; }

.ReportForm__SelectFormatInput {
  display: flex;
  align-items: center; }
  .ReportForm__SelectFormatInput h4 {
    margin-right: 16px; }
  .ReportForm__SelectFormatInput select {
    width: 64px; }

.ManifestDetails {
  display: flex;
  flex-direction: column;
  align-items: baseline; }
  .ManifestDetails__Waybills {
    display: flex;
    flex-direction: column;
    align-self: normal;
    margin-bottom: 16px; }
  .ManifestDetails__WaybillsList {
    display: flex; }
  .ManifestDetails__Header {
    display: flex;
    margin-bottom: 16px;
    align-items: center; }
  .ManifestDetails__Status {
    height: 32px;
    flex: 0 0 auto;
    line-height: 32px;
    border-radius: 4px;
    color: #FFF;
    text-transform: uppercase;
    padding: 0px 16px;
    font-family: "Roboto", sans-serif;
    font-weight: 300; }
    .ManifestDetails__Status_Ok {
      background-color: #00bb00; }
    .ManifestDetails__Status_Error {
      background-color: #df2100; }
    .ManifestDetails__Status_Warning {
      background-color: #f28300; }
  .ManifestDetails__Message {
    font-size: 12px;
    margin-left: 16px; }
  .ManifestDetails__CommonInfo {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 16px; }
  .ManifestDetails__Badges {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 16px; }
    .ManifestDetails__Badges > * {
      flex: 0 0 50%;
      white-space: nowrap; }
  .ManifestDetails__Controls {
    display: flex;
    width: 100%; }
    .ManifestDetails__Controls > * {
      margin-left: 16px; }

.ManifestList {
  width: 100%; }
  .ManifestList__CreateManifest {
    margin-right: 16px; }
  .ManifestList__Controls {
    display: flex;
    flex-shrink: 0;
    position: relative; }
  .ManifestList__CurrentManifest {
    margin: 0 16px;
    white-space: nowrap;
    line-height: 32px;
    font-family: "Roboto", sans-serif;
    color: #888; }
  .ManifestList__Title {
    height: 16px;
    flex: 1 0 auto;
    margin-bottom: 16px;
    line-height: 16px;
    font-family: "Roboto", sans-serif;
    font-weight: lighter;
    font-size: 17px;
    text-transform: uppercase;
    color: #888; }

.PointsDetails__Button {
  display: flex;
  justify-content: center; }

.PointView__Form {
  flex-shrink: 0;
  height: 72px;
  overflow: hidden; }
  .PointView__Form > * {
    display: block; }

.WaypointAction {
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: top;
  margin-top: -2px;
  margin-right: 2px; }

.WaypointActionIcon, .WaypointActionIcon_Warning, .WaypointActionIcon_Error {
  display: inline-block;
  fill: #0b597a; }
  .WaypointActionIcon_Warning {
    fill: #f38400; }
  .WaypointActionIcon_Error {
    fill: #df2100; }

.WaypointAction .tooltip {
  visibility: hidden; }

.WaypointAction:hover .tooltip {
  visibility: visible;
  position: absolute;
  transform: translate(10px, -3px);
  z-index: 3; }

.WaypointAction:hover:after .tooltip {
  position: relative;
  left: 0;
  top: 100%;
  white-space: nowrap; }

.tooltip {
  margin-top: -5px;
  fill: black;
  font-size: 12px;
  font-family: sans-serif; }

.WaybillEdit__Controls {
  display: flex;
  margin-top: 16px; }
  .WaybillEdit__Controls > * {
    margin-right: 16px !important; }

.WaybillEdit__Head {
  display: flex;
  margin-bottom: 16px;
  flex-wrap: wrap; }
  .WaybillEdit__Head > * {
    flex: 0 0 48%; }
  .WaybillEdit__Head > *:nth-of-type(2n - 1) {
    margin-right: 16px; }

html, body, #root {
  height: 100%;
  overflow: hidden; }

h2 {
  height: 16px;
  flex: 1 0 auto;
  line-height: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: lighter;
  font-size: 14px;
  text-transform: uppercase;
  color: #888; }

body {
  font: 13px "Ubuntu", sans-serif;
  line-height: 16px;
  color: #333; }

.vr {
  width: 1px;
  border-left: 1px solid #BBB;
  margin: 0;
  height: 32px;
  align-self: center; }
